var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4","p":"3"}},[_c('view-as-user-selection',{on:{"onUserSelection":_vm.onUserSelection}})],1)],1),(_vm.progressBar)?_c('loading-data',{attrs:{"feedbackText":_vm.$t('global.loading-data')}}):[_c('h1',[_vm._v(_vm._s(_vm.$t("general.suggestion-register")))]),_c('h2',[_vm._v(" "+_vm._s(_vm.$t("general.add-suggestion-subtitle"))+" ")]),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"my-4 text-right",attrs:{"cols":"12","sm":"4"}},[_c('custom-button',{attrs:{"text":_vm.$t('btn.new'),"icon":"add"},on:{"click":function($event){return _vm.navigateToCreateOrEdit('new')}}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.suggestions,"headers":_vm.headers,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('custom-button',{attrs:{"type":"iconBtn","icon":_vm.getStatusObject(item.status).icon,"color":_vm.getStatusObject(item.status).color,"toolTipText":_vm.getStatusObject(item.status).text}})]}},{key:"item.updatedBy",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.updatedBy)+" ("+_vm._s(_vm.convertToDate(item.updatedAt))+")")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[(item.status == 'submitted')?_c('custom-button',{attrs:{"text":_vm.$t('btn.edit'),"icon":"edit"},on:{"click":function($event){return _vm.navigateToCreateOrEdit(item.suggestionId)}}}):_vm._e()],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[(item.status == 'submitted')?_c('custom-button',{attrs:{"text":_vm.$t('btn.delete'),"type":"warning","icon":"delete_forever"},on:{"click":function($event){return _vm.deleteSuggestion(item.suggestionId)}}}):_vm._e()],1)]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }