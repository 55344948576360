<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="4" p="3">
        <view-as-user-selection @onUserSelection="onUserSelection" />
      </v-col>
    </v-row>
    <loading-data
      v-if="progressBar"
      :feedbackText="$t('global.loading-data')"
    ></loading-data>
    <template v-else>
      <h1>{{ $t("general.suggestion-register") }}</h1>
      <h2>
        {{ $t("general.add-suggestion-subtitle") }}
      </h2>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="4" class="my-4 text-right">
          <custom-button
            :text="$t('btn.new')"
            icon="add"
            @click="navigateToCreateOrEdit('new')"
          />
        </v-col>
      </v-row>
      <v-data-table
        :items="suggestions"
        :headers="headers"
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:item.status="{ item }">
          <!-- {{ getStatusObject(item.status).icon }} -->
          <custom-button
            type="iconBtn"
            :icon="getStatusObject(item.status).icon"
            :color="getStatusObject(item.status).color"
            :toolTipText="getStatusObject(item.status).text"
          />
        </template>

        <template v-slot:item.updatedBy="{ item }">
          <span
            >{{ item.updatedBy }} ({{ convertToDate(item.updatedAt) }})</span
          >
        </template>
        <template v-slot:item.edit="{ item }">
          <div class="my-2">
            <custom-button
              v-if="item.status == 'submitted'"
              :text="$t('btn.edit')"
              icon="edit"
              @click="navigateToCreateOrEdit(item.suggestionId)"
            />
          </div>
        </template>
        <template v-slot:item.delete="{ item }">
          <div class="my-2">
            <custom-button
              v-if="item.status == 'submitted'"
              :text="$t('btn.delete')"
              type="warning"
              icon="delete_forever"
              @click="deleteSuggestion(item.suggestionId)"
            />
          </div>
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import { db } from "@/main";
import LoadingData from "@/components/utilities/LoadingData.vue";
import ViewAsUserSelection from "@/components/ViewAsUserSelection.vue";
import CustomButton from "@/components/CustomButton.vue";
import { isEqual, truncate } from "lodash";
import {
  dateToCustomFormat,
  timestampToDate,
} from "@/helpers/dateTimeConvertingFunctions";
import { workersSuggestionsStatuses } from "@/helpers/constants";
export default {
  components: { ViewAsUserSelection, CustomButton, LoadingData },
  data() {
    return {
      progressBar: false,
      suggestions: [],
      selectedUserId: this.$root.currentUserData.userId,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    headers() {
      let items = [
        { text: `1.${this.$t("general.suggestion")}`, value: "shortenedText" },
        { text: `2.${this.$t("general.created-at")}`, value: "dateKey" },
        { text: `3.${this.$t("global.updatedBy")}`, value: "updatedBy" },
        { text: `4.${this.$t("tasks.status")}`, value: "status" },
        { text: `5.${this.$t("btn.edit")}`, value: "edit" },
        { text: `6.${this.$t("btn.delete")}`, value: "delete" },
      ];
      return items.map((item) => {
        return { text: item.text, value: item.value, align: "center" };
      });
    },
  },
  methods: {
    getStatusObject(status) {
      let foundStatus = workersSuggestionsStatuses.find((stat) =>
        isEqual(stat.value, status)
      );
      return foundStatus || {};
    },
    async onUserSelection(user) {
      this.selectedUserId = user.id;
      await this.getData();
    },
    navigateToCreateOrEdit(suggestionId) {
      this.$router.push({
        name: `addOrEditWorkerSuggestion`,
        params: {
          suggestionId: suggestionId,
        },
      });
    },
    async getData() {
      this.progressBar = true;
      let suggestionsResponse = await db
        .collection("workersSuggestions")
        .where("userId", "==", this.selectedUserId)
        .get();
      let itemsList = [];
      suggestionsResponse.docs.forEach((doc) => {
        let data = doc.data();
        data.suggestionId = doc.id;
        data.shortenedText = truncate(data.suggestionText, { length: 100 });
        itemsList.push(data);
      });
      this.suggestions = itemsList;
      this.progressBar = false;
    },
    async deleteSuggestion(suggestionId) {
      var conf = confirm("Vai Jūs tiešām vēlaties dzēst šo iniacitīvu?");
      if (conf) {
        this.progressBar = true;
        let suggestionsList = [...this.suggestions];
        let foundIndex = suggestionsList.findIndex((suggestion) =>
          isEqual(suggestion.suggestionId, suggestionId)
        );
        if (foundIndex > -1) {
          suggestionsList.splice(foundIndex, 1);
          this.suggestions = suggestionsList;
          await db.collection("workersSuggestions").doc(suggestionId).delete();
        }
        this.progressBar = false;
      }
    },
    convertToDate(timeStamp) {
      let date = timestampToDate(timeStamp);
      return dateToCustomFormat(date, "YYYY.MM.DD. HH:mm:ss");
    },
  },
};
</script>

<style>
</style>